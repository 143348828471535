import React from 'react';
import { httpGET } from '../lib/http';
import './FileList.css'

class FileListDayItem extends React.PureComponent {
    onClick = () => {
        this.props.onClick(this.props.file);
    }
    render() {
        return <div
            className={'file ' + (this.props.selected ? 'selected' : '')}
            onClick={this.onClick}>{this.props.dayName}&nbsp;{this.props.file.time}
        </div>
    }
}

class FileListDay extends React.PureComponent {

    render() {
        return <div className='day'>
            <div className='title'>{this.props.day.name}</div>
            {this.props.day.files.map((file, index) => {
                return <FileListDayItem
                    selected={(this.props.clickedItem || {}).isoDate === file.isoDate}
                    file={file}
                    key={index}
                    dayName={this.props.day.name}
                    onClick={this.props.onClick}>
                </FileListDayItem>
            })}
        </div>
    }
}


export class FileList extends React.PureComponent {

    state = {
        days: [],
        searching: false,
        error: null
    };

    componentDidMount() {
        this.setState({ searching:true});
        httpGET('/api/cameras/'+this.props.cameraName+'/records')
        .then(res => {
            this.setState({ searching:false, days: res.data });
        })
        .catch(error=>{
            this.setState({ searching:false, days: [] });
        })
    }

    render() {
        return <div className='file-list'>
            {this.state.searching && <div className='text-center'>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Caricamento...</span>
                </div>
            </div>}
            
            {(!this.state.searching && this.state.days.length === 0) 
            && <div className='text-center p-3'>Non ci sono video per questa telecamera</div>}
            
            {(!this.state.searching) && this.state.days.map(day => 
                <FileListDay 
                    clickedItem = {this.props.clickedItem}
                    onClick={this.props.onClick} 
                    key={day.sortKey} 
                    day={day}>
                </FileListDay>)}
        </div>;
    }
}
