import React from 'react';
import { DateTime } from "luxon";
import { httpGET } from '../lib/http';
import './EventsList.css'

class EventListItem extends React.PureComponent {
    onClick = () => {
        this.props.onClick(this.props.event);
    }

    date = () =>{
        const dt = DateTime.fromISO(this.props.event.date);
        return dt.toLocaleString(DateTime.DATETIME_MED);
    }
    render() {
        return <div
            className={'event ' + (this.props.selected ? 'selected' : '')}
            onClick={this.onClick}>{this.date()}
        </div>
    }
}
export class EventsList extends React.PureComponent {

    state = {
        events: [],
        searching: false,
        error: null
    };

    componentDidMount() {
        this.setState({ searching: true });
        httpGET('/storage/' + this.props.cameraName + '/events/events.json')
            .then(res => {
                const events = res.data;
                events.sort((a,b)=> {
                    if (a.date === b.date) return 0;
                    return a.date<b.date ? 1:-1;
                });
                this.setState({ searching: false, events: events });
            })
            .catch(error => {
                this.setState({ searching: false, events: [] });
            })
    }

    render() {
        return <div className='events-list'>
            {this.state.searching && <div className='text-center'>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Caricamento...</span>
                </div>
            </div>}

            {(!this.state.searching && this.state.events.length === 0)
                && <div className='text-center p-3'>Non ci sono movimenti per questa telecamera</div>}

            {(!this.state.searching) && this.state.events.map(event =>
                <EventListItem
                    selected={(this.props.clickedItem || {}).date === event.date}
                    onClick={this.props.onClick}
                    key={event.date}
                    event={event}>
                </EventListItem>)}
        </div>;
    }
}
