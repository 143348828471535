import React from 'react';
//import { CameraCommands } from './CameraCommands';
import { TabSelector } from './TabSelector';
import { httpGET } from '../lib/http';
import './CameraSelector.css'

export class CameraSelector extends React.PureComponent {

    state = {
        cameras: []
    }

    componentDidMount() {
        httpGET('/api/cameras')
            .then(res => {
                const cameras = res.data.map(c => {
                    c.id = c.name;
                    return c;
                });
                this.setState({ cameras: cameras });
            })
    }

    render = () => 
        <TabSelector
            className='camera-list'
            items = {this.state.cameras}
            selectedItem = {{id: this.props.cameraName}}
            onClick={this.props.onClick}>
            {/*this.props.cameraName && <CameraCommands
                cameraName={this.props.cameraName}
                key={this.props.cameraName}
            ></CameraCommands>*/}
        </TabSelector>
}

export class CameraSelector2 extends React.PureComponent {
    state = {
        cameras: []
    };

    componentDidMount() {
        httpGET('/api/cameras')
            .then(res => {
                this.setState({ cameras: res.data });
            })
    }



    render() {
        return <div className='camera-list'>
            {this.state.cameras.map(camera =>
                <div className={'camera ' + (this.props.cameraName === camera.name ? 'selected' : '')}
                    key={camera.name}
                    onClick={() => this.props.onClick(camera.name)}>
                    {camera.name}
                </div>)
            }
            {/*this.props.cameraName && <CameraCommands
                cameraName={this.props.cameraName}
                key={this.props.cameraName}
            ></CameraCommands>*/}
        </div>;
    }

}
