import React from 'react';

export class TabSelector extends React.PureComponent {      

    
    render() {
        return <div className={'tab-list '+(this.props.className || '')}>
            {this.props.items.map(item => 
                <div className={'tab-list-item '+((this.props.selectedItem || {}).id === item.id ? 'selected':'')} 
                    key={item.id} 
                    onClick={()=>this.props.onClick(item.id)}>
                    {item.name}
                </div>)
            }
            {this.props.children}
        </div>;
    }

}
