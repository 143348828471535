import React from 'react';
import config from '../config.json';
import './VideoPlayer.css'

export class VideoPlayer extends React.PureComponent {

    state={
        speed:1
    }
    videoRef = React.createRef();

    setSpeed =(speed) => {
        this.setState({speed: speed});
        this.videoRef.current.playbackRate = speed;
    }
    renderButton = (text, speed) => 
        <button
            onClick= {()=> this.props.videoFile && this.setSpeed(speed)}
            className= {speed === this.state.speed ? 'speed-active': ''}>
            {text}
        </button>

    render(){
        return <div className='video-container'>
            <video  className='video' controls ref = {this.videoRef}>
                <source src={this.props.videoFile ? config.apiHost+this.props.videoFile.path : ''} type="video/mp4"/>
                Il tuo browser non supporta questo video
            </video>
            <div className='video-controls' style={{textAlign:'right'}}>
                Velocità: 
                {this.renderButton('0.5x',0.5)}
                {this.renderButton('1x',1)}
                {this.renderButton('2x',2)}
                {this.renderButton('4x',3)}
            </div>
        </div>
    }
}
