import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React from 'react';
import { CameraSelector } from './components/CameraSelector';
import { VideoPlayer } from './components/VideoPlayer';
import { FileList } from './components/FileList';
import { TabSelector } from './components/TabSelector';
import { EventsList } from './components/EventsList';
import { EventImage } from './components/EventImage';

class App extends React.PureComponent {

    state = {
        cameraName: null,
        clickedFile: null,
        operation: { id: 'files' },
        clickedEvent: null
    }
    operations = [{ id: 'files', name: 'Registrazioni' }, { id: 'events', name: 'Movimenti' }];

    onFileClick = (file) => {
        this.setState({ clickedFile: file })
    }

    onEventClick = (event) => {
        this.setState({ clickedEvent: event })
    }

    onCameraSelectorClick = (name) => {
        this.setState({ cameraName: name, clickedEvent: null })
    }

    onOperationSelectionClick = (operation) => {
        this.setState({ operation: { id: operation } })
    }
    render() {
        return <React.Fragment>
            <CameraSelector
                cameraName={this.state.cameraName}
                onClick={this.onCameraSelectorClick}>
            </CameraSelector>

            {this.state.cameraName && <TabSelector
                className='operations-list'
                selectedItem={this.state.operation}
                onClick={this.onOperationSelectionClick}
                items={this.operations}>
            </TabSelector>}

            {this.state.cameraName && (this.state.operation.id === 'files') && <div  className='disposition'>
                <VideoPlayer
                    key={(this.state.clickedFile || {}).isoDate}
                    videoFile={this.state.clickedFile}>
                </VideoPlayer>
                <FileList
                    clickedItem={(this.state.clickedFile || {})}
                    onClick={this.onFileClick}
                    key={'records' + this.state.cameraName}
                    cameraName={this.state.cameraName}>
                </FileList>
            </div>}

            {this.state.cameraName && (this.state.operation.id === 'events') && <div className='disposition'>
                <EventImage
                    cameraName = {this.state.cameraName}
                    key={Math.random()}
                    event={(this.state.clickedEvent || {})}
                ></EventImage>
                <EventsList
                    className='event'
                    clickedItem={(this.state.clickedEvent || {})}
                    onClick={this.onEventClick}
                    key={'events' + this.state.cameraName}
                    cameraName={this.state.cameraName}>
                </EventsList>
            </div>}
        </React.Fragment>
    }
}

export default App;
