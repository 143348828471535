import React from 'react';
import config from '../config.json';
import './EventImage.css'
export class EventImage extends React.PureComponent {

    state = {
        index: 0,
    }
    preloaded = false;
    interval = null;
    componentDidMount() {
        setInterval(() => {
            this.setState({ index: (this.state.index + 1) % 3 })
        }, 1000);
    }
    images = [];
    imageUrl = () => {
        if (this.props.event && this.props.event.attachments) {
            if (!this.preloaded){
                this.preloaded = true;
                for (var i = 0; i < this.props.event.attachments.length; i++) {
                    this.images[i] = new Image();
                    this.images[i].src = config.apiHost + '/storage/' + this.props.cameraName + '/events/' + this.props.event.attachments[i];
                }
            }
            return config.apiHost + '/storage/' + this.props.cameraName + '/events/' + this.props.event.attachments[this.state.index];
        }
        return null;
    }
    
    componentWillUnmount() {
        clearTimeout(this.interval);
    }

    style = () => {
        return {
            backgroundRepeat:'no-repeat',
            backgroundPosition:'center',
            backgroundSize:'contain',
            backgroundImage:"url("+this.imageUrl()+")"
        }
    }
    render() {
        return <div className='event-image-container'>
            <div style = {this.style()} className='event-image' ref={this.containerRef}>
            </div>
        </div>
    }
}
